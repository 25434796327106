<template>
  <div>
    <b-card>
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Name"
              label-cols-md="2"
              label-for="event-name"
              class="mb-2"
            >
              <b-form-input
                id="event-name"
                v-model="eventData.activity_name"
                placeholder="Jomaa, Taraweeh, Eid Adha, Eid Fitr, etc"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Occurrence"
              label-cols-md="2"
              label-for="event-occurrence"
            >
              <b-form-radio-group
                id="event-occurrence"
                v-model="addBooking.occurrence"
                :options="options"
              />
            </b-form-group>
          </b-col>
          <!-- primary -->
          <span>
            <feather-icon
              id="popover-button-variant"
              icon="AlertCircleIcon"
              size="20"
            />
            Define the differences
          </span>

          <b-popover
            target="popover-button-variant"
            variant="primary"
            triggers="focus"
            placement="top"
          >
            <template #title>
              <span>TEST!</span>
            </template>
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non quae quibusdam nihil sapiente, voluptatum ipsam?</span>
          </b-popover>
          <b-col
            v-show="addBooking.occurrence === 'one_time'"
            md="6"
          >
            <b-form-group>
              <h5>Start Time</h5>
              <flat-pickr
                v-model="addBooking.start_time"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="addBooking.occurrence === 'one_time'"
            md="6"
          >
            <b-form-group>
              <h5>End Time</h5>
              <flat-pickr
                v-model="addBooking.end_time"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-show="addBooking.occurrence === 'dates'"
            md="6"
          >
            <b-form-group>
              <h5>Selected Dates</h5>
              <flat-pickr
                v-model="multiDate"
                class="form-control"
                :config="{ mode: 'multiple',dateFormat: 'Y-m-d', minDate: 'today'}"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="addBooking.occurrence === 'daily'"
            md="6"
          >
            <b-form-group>
              <h5>Daily</h5>
              <flat-pickr
                v-model="multiRang"
                class="form-control"
                :config="{ mode: 'range',dateFormat: 'Y-m-d', minDate: 'today'}"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="addBooking.occurrence === 'weekly'"
            md="6"
          >
            <b-form-group>
              <h5>Weekly</h5>
              <flat-pickr
                v-model="addBooking.startDate"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            md="12"
          >
            <b-form-group
              label="General Notes"
              label-for="notes"
              class="mb-2"
            >
              <b-form-textarea
                id="notes"
                v-model="eventData.activity_description"
                placeholder="General notes for this event"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showBTN"
            cols="12"
            class="mt-50"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="addEventsData()"
            >
              Creat Event
            </b-button>
            <b-button
              variant="secondary"
            >
              Dublicate PREV. Event
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div id="drink-holder">
              <b-form-select
                v-model="selected"
                :select-size="2"
                :options="optionsSel"
                class="overflow"
                value-field="id"
                text-field="name"
              />
            </div>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="mr-2"
                @click="add"
              >
                <span class="text-nowrap">Add New Session</span>
              </b-button>
              <b-button
                variant="secondary"
              >
                <span class="text-nowrap">Duplicate Prev Day Sessions</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="table"
        show-empty
        empty-text="There is no Sessions for this Event!"
        :items="sessionsData"
        :fields="addTableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        aria-busy="true"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <template #cell(date-time)="data">
          Start: {{ data.item.session_start }} <br> <hr>
          End: {{ data.item.session_end }}
        </template>
        <template #cell(targetAudience)="data">
          {{ data.item.session_target_gender }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item @click="show(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteBS(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-card v-if="showAdd">
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <h4>Add New Session</h4>
        <b-row class="mt-3">
          <b-col md="6">
            <b-form-group
              label="Session Name"
              label-cols-md="3"
              label-for="session-name"
              class="mb-2"
            >
              <b-form-input
                id="session-name"
                v-model="addSession.session_name"
                placeholder="ex, Female session"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date"
              label-cols-md="3"
              class="mb-2"
            >
              <b-form-input
                id="session-date"
                v-model="addSession.date"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="From"
              label-cols-md="3"
            >
              <b-form-timepicker
                v-model="addSession.start"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="To"
              label-cols-md="3"
            >
              <b-form-timepicker
                v-model="addSession.end"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Speakers"
              label-cols-md="3"
              label-for="session-speakers"
              class="mb-2"
            >
              <b-form-input
                id="session-speakers"
                v-model="addSession.session_speakers"
                placeholder="ex, DR. Ahmed Magdy"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="General Notes"
              label-cols-md="3"
              label-for="notes"
              class="mb-2"
            >
              <b-form-textarea
                id="notes"
                v-model="addSession.notes"
                placeholder="General notes for this event"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Language"
              label-cols-md="3"
              label-for="notes"
              class="mb-2"
            >
              <b-form-select
                v-model="addSession.lang"
                :options="langOptions"
                class="mb-3"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Target Gender"
              label-cols-md="3"
              label-for="gender"
            >
              <b-form-radio-group
                id="gender"
                v-model="addSession.gender"
                :options="genderOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Capacity"
              label-cols-md="3"
              label-for="capacity"
              class="mb-2"
            >
              <b-form-input
                id="capacity"
                v-model="addSession.capacity"
                type="number"
                max="20"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Family Members"
              label-cols-md="3"
              label-for="capacity"
              class="mb-2"
            >
              <b-form-input
                id="capacity"
                v-model="addSession.f_members"
                type="number"
                max="4"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="creatEvent()"
            >
              Add Session
            </b-button>
            <!-- <b-button
              variant="outline-secondary"
            >
              Cancel
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card v-if="sessionsINDData">
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <h4>Edit Session</h4>
        <b-row class="mt-3">
          <b-col md="6">
            <b-form-group
              label="Session Name"
              label-cols-md="3"
              label-for="session-name"
              class="mb-2"
            >
              <b-form-input
                id="session-name"
                v-model="individualSessionData.session_address"
                placeholder="ex, Female session"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date"
              label-cols-md="3"
              class="mb-2"
            >
              <b-form-input
                id="session-date"
                v-model="addSession.date"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="From"
              label-cols-md="3"
            >
              <b-form-timepicker
                v-model="individualSessionData.session_start"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="To"
              label-cols-md="3"
            >
              <b-form-timepicker
                v-model="individualSessionData.session_start"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Speakers"
              label-cols-md="3"
              label-for="session-speakers"
              class="mb-2"
            >
              <b-form-input
                id="session-speakers"
                v-model="addSession.session_speakers"
                placeholder="ex, DR. Ahmed Magdy"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="General Notes"
              label-cols-md="3"
              label-for="notes"
              class="mb-2"
            >
              <b-form-textarea
                id="notes"
                v-model="addSession.notes"
                placeholder="General notes for this event"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Language"
              label-cols-md="3"
              label-for="notes"
              class="mb-2"
            >
              <b-form-select
                v-model="individualSessionData.session_languages"
                :options="langOptions"
                class="mb-3"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Target Gender"
              label-cols-md="3"
              label-for="gender"
            >
              <b-form-radio-group
                id="gender"
                v-model="addSession.gender"
                :options="genderOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Capacity"
              label-cols-md="3"
              label-for="capacity"
              class="mb-2"
            >
              <b-form-input
                id="capacity"
                v-model="addSession.capacity"
                type="number"
                max="20"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Family Members"
              label-cols-md="3"
              label-for="capacity"
              class="mb-2"
            >
              <b-form-input
                id="capacity"
                v-model="addSession.f_members"
                type="number"
                max="4"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="creatEvent()"
            >
              Add Session
            </b-button>
            <!-- <b-button
              variant="outline-secondary"
            >
              Cancel
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BForm, BFormTextarea, BFormTimepicker,
  BPagination, BFormGroup, BFormRadioGroup, BFormSelect, VBPopover, BPopover, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import flatPickr from 'vue-flatpickr-component'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import bookingPrayers from '../BookingPrayers'
import bookingPrayersModule from '../BookingPrayersModule'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
    BFormTextarea,
    BButton,
    BTable,
    BFormTimepicker,
    BFormSelect,
    BPopover,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      langOptions: [
        { id: '1', name: 'Arabic' },
        { id: '2', name: 'English' },
      ],
      options: [
        { text: 'Once', value: 'one_time' },
        // { text: 'Daily', value: 'daily' },
        // { text: 'Weekly', value: 'weekly' },
        // { text: 'Selected Dates', value: 'dates' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
      optionsSel: [
        { id: 'A', name: '15 Feb 2021 wed' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
      ],
      selected: null,
      multiDate: null,
      multiRang: null,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
      },
      showAdd: false,
      sessionsINDData: false,
    }
  },
  watch: {
    individualSessionData(val) {
      if (val) {
        this.sessionsINDData = true
      }
    },
  },
  setup() {
    const EVENTS_MODULE = 'eventsModule'
    // Register module
    if (!store.hasModule(EVENTS_MODULE)) store.registerModule(EVENTS_MODULE, bookingPrayersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_MODULE)) store.unregisterModule(EVENTS_MODULE)
    })

    const {
      addTableColumns, events, addSession, addBooking, addEventsData, showBTN, sessionsData, eventData, sessionId,
      individualSessionData,
    } = bookingPrayers()

    return {
      addTableColumns,
      events,
      addSession,
      addBooking,
      addEventsData,
      showBTN,
      sessionsData,
      eventData,
      sessionId,
      individualSessionData,
    }
  },
  methods: {
    getDate(dtate) {
      const date = new Date(dtate)
      const finale = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
      console.log(finale)
      return finale
    },
    add() {
      this.showAdd = true
    },
    show(id) {
      this.sessionId = id
      console.log(id)
    },
  },
}
</script>

<style lang="scss">
#event-occurrence {
  padding-top: 7px;
}
select {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding:10px;
  margin:-5px -20px -5px -5px;
}

#drink-holder select option {
  display:inline-block;
}

#drink-holder {
  display:inline-block;
  vertical-align:top;
  overflow:hidden;
}

.slick {
    text-align: center;
    text-align: -webkit-center;
}
.overflow {
    overflow: auto;
    border: 0;
    margin: 1px;
}

option {
    padding: 1rem;
    background-color: #00a5af;
    color: white;
    margin: 10px;
}

.overflow::-webkit-scrollbar {
  height: 5px;
  color: #00a5af
}
.overflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(255, 255, 255);
  border-radius: 3px;
}

.overflow::-webkit-scrollbar-thumb {
  background: #00a5af;
  border-radius: 3px;
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
